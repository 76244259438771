import lsApi from '../api'
import _ from 'lodash';
import tkeys from '../utils/translation_hepler'
//import LRU from 'lru-cache'

// import redis from 'redis'
// const client = redis.createClient()


export default {

    FETCH_ATTRS: ({ commit, dispatch, state }) => {
        let attrs = _.get(state, 'attrs', false);
        if (_.size(attrs) > 0) {

            return Promise.resolve(attrs)
        } else {
            return lsApi.getAttributes(state).then(rs => {

                commit('SET_ATTRS', rs)
            })
        }
    },

    FETCH_SITE_DATA: ({ commit, dispatch, state }) => {


        let sites = _.get(state, 'sites', false);
        let cities = _.get(state, 'cities', false);
        // console.log('cities', cities)
        if (_.size(sites) > 0 && _.size(cities) > 0) {

            //   console.log('has sites')
            if (!_.has(sites, 'hongkong')) {
                sites['hong-kong'] = {
                    'url_long': 'hong-kong',
                    'index': 3
                };
                sites['hongkong'] = {
                    'url_long': 'hong-kong',
                    'index': 3
                };
            }

            return Promise.resolve()
        } else {

            return lsApi.getSites(state).then(rs => {
                //console.log('get sites:', rs);
                // console.log('--- get sites')

                commit('SET_SITES', { gsites: rs.sites, gcities: rs.citylist })
            })

        }

    },

    FETCH_TRANSLATION_DATA: ({ commit, dispatch, state }) => {

        let cache_key = 'translation_1.6.7'
        let iso2 = _.get(state.curSite, 'iso2', 'HK')
        // console.log('state.curSite', state.curSite)
        let tran_iso2 = _.get(state, ['translation', 'en_us', 'iso2'], false);

        let trans = _.get(state, 'translation', false);
        // console.log('iso--', iso2, tran_iso2, _.get(trans, 'cache_key'))
        // if(_.has(trans,'en_us')){
        if (_.get(trans, 'cache_key') == cache_key && tran_iso2 == iso2 && _.has(trans, 'en_us')) {

            //   console.log('has translation')

            if (_.get(trans, 'is_filtered2') != 1) {
                trans = tkeys.getUsedTrans(trans, state)
                trans.en_us.iso2 = iso2
                state.translation = trans
            }
            // console.log('tkeys2=', )

            return Promise.resolve(trans)
        } else {
            return lsApi.getPhrase(state).then(rs => {
                rs.en_us.iso2 = iso2
                commit('SET_TRANSLATION', { translation: rs, cache_key })
            })
        }


    },
    FETCH_IP_CITY: ({ commit, dispatch, state }) => {
        return lsApi.getIpCity(state).then(rs => {
            commit('SET_IP_CITY', { ipCity: rs })
        })
    },


}
