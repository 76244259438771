<template>
  <div class="bg-faf text-1d">
    <topName ref="topNav" :showMobileTop="false" />
    <div class="sm_hide" style="height: 50px;"></div>
    <div class="page_container- hideMobileTop">
      <div v-if="curSite.iso2=='GB'" class="bg-faf pb-5 text-center">
        <div class="bg-image position-relative text-f6f py-5 ff-prata fs-35 mb-4" data-id=""
          style="background-image: url(https://cdn-cf2.lovestruck.com/upload/gen/img/v15/top_red_bg_v15.png); background-position: bottom;z-index:2;height: 170px;">
            That's all booked, <span class="leadName"></span>!
        </div>
        <div class="px-3">
        <div class="pt-4 pb-5 text-555" style="line-height: 28px;">Congratulations on taking the first step to finding love and happiness.<br/>Get ready for an amazing journey!</div>
          <div class="matchmakerItem mx-auto">
            <div class="bg-image head" style="background-image: url(https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_com_black/london/matchmaker_lorraine.jpg);">
            </div>
            <div class="textBox d-flex align-items-center">
              <div class="text-1d fs-22 mb-2 fw-700" data-id="">We'll contact you on <span class="phoneNumber"></span><br/><span class="fw-600 bookTime"></span></div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="bg-faf pb-5 text-center">
        <div class="bg-image position-relative text-f6f py-5 ff-prata fs-35 sm_hide" data-id="6972"
          style="background-image: url(https://cdn-cf2.lovestruck.com/upload/gen/img/v15/top_red_bg_v15.png); background-position: bottom;z-index:2;height: 170px;">
          <div class="welcomeText" style="display: none;" v-html="$t('welcome_ls')"></div>
        </div>
        <div class="bg-image position-relative text-f6f py-5 ff-prata fs-29 sm_show" data-id="6973"
          style="background-image: url(https://cdn-cf2.lovestruck.com/upload/gen/img/v15/top_red_bg_v15.png); background-position: bottom;z-index:2;height: 170px;">
          <div class="welcomeText" style="display: none;" v-html="$t('welcome_ls_m')"></div>
        </div>

        <div class="px-3">
          <div class="pt-4 pb-5 text-555 sm_hide" data-id="6974" style="line-height: 28px;" v-html="$t('ty_desc_txt')"></div>
          <div class="pt-4 pb-5 text-555 sm_show" data-id="6975" style="line-height: 28px;" v-html="$t('ty_desc_txt_m')"></div>

          <h3 class="text-ee1 ff-prata fs-35 mb-4 sm_hide" data-id="6976" v-html="$t('what_next_h')"></h3>
          <h3 class="text-ee1 ff-prata fs-29 mb-4 sm_show" data-id="6976" v-html="$t('what_next_h')"></h3>

          <div class="matchmakerItem mx-auto">
            <div class="bg-image head"
              style="background-image: url(https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls.com/hongkong/matchmaker_angeline.jpg);">
            </div>
            <div class="textBox">
              <div class="text-1d fs-22 mb-2 fw-700" data-id="6980" v-html="$t('will_call_soon')"></div>
              <div class="text-555 mb-4 sm_hide" data-id="6977" style="line-height: 28px;" v-html="$t('will_call_soon_desc')">

              </div>
              <div class="text-555 mb-4 sm_show" data-id="6978" style="line-height: 28px;" v-html="$t('will_call_soon_desc_m')">
              </div>
              <div class="text-555" data-id="6979" v-html="$t('will_call_soon_desc2')"></div>
            </div>
          </div>
        </div>

      </div>
      
      <!--You're on the right path for love-->
      <div class="pt-5 pb-3 bg-white text-center px-3-">
        
        <h3 class="text-ee1 ff-prata fs-35 mb-4 sm_hide" data-id="6981" v-html="$t('right_path_love')"></h3>
        <h3 class="text-ee1 ff-prata fs-29 mb-4 sm_show" data-id="6982" v-html="$t('right_path_love_m')"></h3>
        <div class="mb-4 text-555">
          <div class="sm_hide mb-4" data-id="6983" v-html="$t('right_path_love_desc')"></div>
          <div class="sm_show mb-4" data-id="6984" v-html="$t('right_path_love_desc_m')"></div>

          <div class="text-1d rightPathText" data-id="6985" v-html="$t('right_path_love_h1')">
          </div>
          <div class="grade-line my-4 mx-auto"></div>
          <div class="text-1d rightPathText" data-id="6986" v-html="$t('right_path_love_h2')">
          </div>
          <div class="grade-line my-4 mx-auto"></div>
          <div class="text-1d rightPathText" data-id="6987" v-html="$t('right_path_love_h3')"></div>
        </div>
      </div>
      <!--Meet our passionate team-->
      <div class="py-5 bg-faf text-center px-3">
        <h3 class="text-ee1 ff-prata fs-35 mb-5 sm_hide" data-id="6988" v-html="$t('meet_our_team')"></h3>
        <h3 class="text-ee1 ff-prata fs-29 mb-5 sm_show" data-id="6989" v-html="$t('meet_our_team_m')"></h3>

        <div class="matchmakerItem border-0 mx-auto mb-4">
          <div class="bg-image head"
            :style="`background-image: url(https://cdn-cf2.lovestruck.com/upload-m${$t('frontpage_section6_block1_img_file')});`">
          </div>
          <div class="textBox">
            <div class="text-1d mb-2"><span class="fs-22 mr-2 fw-700">{{$t('frontpage_section6_block1_header')}}</span>
              <span class="sm_line_hide">|</span>
              <br class="sm_show" />
              <span class="text-555 ml-2 text-uppercase" v-html="$t('frontpage_section6_block1_job')"></span>
            </div>
            <div class="text-555" v-html="$t('frontpage_section6_block1_description')"></div>
          </div>
        </div>
        <div class="matchmakerItem border-0 mx-auto mb-4">
          <div class="bg-image head"
            :style="`background-image: url(https://cdn-cf2.lovestruck.com/upload-m${$t('frontpage_section6_block3_img_file')});`">
          </div>
          <div class="textBox">
            <div class="text-1d mb-2"><span class="fs-22 mr-2 fw-700">{{$t('frontpage_section6_block3_header')}}</span>
              <span class="sm_line_hide">|</span>
              <br class="sm_show text-uppercase" /><span
                class="text-555 ml-2" v-html="$t('frontpage_section6_block3_job')"></span>
            </div>
            <div class="text-555" v-html="$t('frontpage_section6_block3_description')"></div>
          </div>
        </div>
        <div class="my-5 text-555 position-relative mx-auto" style="max-width: 420px;">
          <div class="matchmaker-quto-left"></div>
          <div class="font-italic sm_hide" data-id="6990" v-html="$t('matchamker_say1')"></div>
          <div class="font-italic sm_show" data-id="6991" v-html="$t('matchamker_say1_m')"></div>
          <div class="matchmaker-quto-right"></div>
          <div class="mt-3">- {{$t('frontpage_section6_block3_header')}}</div>
        </div>

        <div class="matchmakerItem border-0 mx-auto mb-4">
          <div class="bg-image head"
            :style="`background-image: url(https://cdn-cf2.lovestruck.com/upload-m${$t('frontpage_section6_block4_img_file')});`">
          </div>
          <div class="textBox">
            <div class="text-1d mb-2"><span class="fs-22 mr-2 fw-700">{{$t('frontpage_section6_block4_header')}}</span>
              <span class="sm_line_hide">|</span>
              <br class="sm_show text-uppercase" /><span
                class="text-555 ml-2" v-html="$t('frontpage_section6_block4_job')"></span>
            </div>
            <div class="text-555" v-html="$t('frontpage_section6_block4_description')"></div>
          </div>
        </div>
        <div class="matchmakerItem border-0 mx-auto mb-4">
          <div class="bg-image head"
            :style="`background-image: url(https://cdn-cf2.lovestruck.com/upload-m${$t('frontpage_section6_block2_img_file')});`">
          </div>
          <div class="textBox">
            <div class="text-1d mb-2"><span class="fs-22 mr-2 fw-700">{{$t('frontpage_section6_block2_header')}}</span>
              <span class="sm_line_hide">|</span>
              <br class="sm_show text-uppercase" /><span
                class="text-555 ml-2" v-html="$t('frontpage_section6_block2_job')"></span>
            </div>
            <div class="text-555" v-html="$t('frontpage_section6_block2_description')"></div>
          </div>
        </div>
        <div class="mt-5 text-555 position-relative mx-auto" style="max-width: 475px;">
          <div class="matchmaker-quto-left"></div>
          <div class="font-italic sm_hide" data-id="6992" v-html="$t('matchamker_say2')"></div>
          <div class="font-italic sm_show" data-id="6993" v-html="$t('matchamker_say2_m')"></div>
          <div class="matchmaker-quto-right"></div>
          <div class="mt-3">- {{$t('frontpage_section6_block2_header')}}</div>
        </div>
      </div>

      <!--Exceptional service awaits you-->
      <div class="py-5 bg-white text-center">
        <h3 class="text-ee1 ff-prata fs-32 mb-3 sm_hide" data-id="6994" v-html="$t('service_await_u')"></h3>
        <h3 class="text-ee1 ff-prata fs-28 mb-3 sm_show" data-id="6995" v-html="$t('service_await_u_m')"></h3>
        <div class="text-7e fs-20 mb-5 sm_hide" data-id="6996" v-html="$t('service_await_u_desc')"></div>
        <div class="text-7e fs-20 mb-5 sm_show" data-id="6997" v-html="$t('service_await_u_desc_m')"></div>

        <div class="position-relative mx-auto text-left mb-5 px-3" style="max-width: 550px;">
          <div class="happenLine bg-ee1" style="top:18px;left: 37px;height: 83%;"></div>
          <div class="d-flex justify-content-start mb-4">
            <div class="mr-4">
              <div class="happenItemN v12 ff-helve">1</div>
            </div>
            <div>
              <p class="text-555 fw-500 fs-20 mb-3" data-id="6998" v-html="$t('service_await_h1')"></p>
              <p class="text-555 mb-0" data-id="6999" v-html="$t('service_await_d1')"></p>
            </div>
          </div>

          <div class="d-flex justify-content-start mb-4">
            <div class="mr-4">
              <div class="happenItemN v12 ff-helve">2</div>
            </div>
            <div>
              <p class="text-555 fw-500 fs-20 mb-3" data-id="7000" v-html="$t('service_await_h2')"></p>
              <p class="text-555 mb-0" data-id="7001" v-html="$t('service_await_d2')"></p>
            </div>
          </div>

          <div class="d-flex justify-content-start mb-4">
            <div class="mr-4">
              <div class="happenItemN v12 ff-helve">3</div>
            </div>
            <div>
              <p class="text-555 fw-500 fs-20 mb-3" data-id="7002" v-html="$t('service_await_h3')"></p>
              <p class="text-555 mb-0" data-id="7003" v-html="$t('service_await_d3')"></p>
            </div>
          </div>

          <div class="d-flex justify-content-start mb-4">
            <div class="mr-4">
              <div class="happenItemN v12 ff-helve">4</div>
            </div>
            <div>
              <p class="text-555 fw-500 fs-20 mb-3" data-id="7004" v-html="$t('service_await_h4')"></p>
              <p class="text-555 mb-0" data-id="7005" v-html="$t('service_await_d4')"></p>
            </div>
          </div>


          <div class="d-flex justify-content-start mb-4">
            <div class="mr-4">
              <div class="happenItemN v12 ff-helve">5</div>
            </div>
            <div>
              <p class="text-555 fw-500 fs-20 mb-3" data-id="7006" v-html="$t('service_await_h5')"></p>
              <p class="text-555 mb-0" data-id="7007" v-html="$t('service_await_d5')"></p>
            </div>
          </div>



        </div>

        <div class="px-3">
          <div class="matchmakerItem mx-auto" style="max-width: 515px;">
            <div>
              <img width="50" src="https://cdn-cf2.lovestruck.com/upload/gen/img/v15/refresh_red@2x.png" />
            </div>
            <div class="textBox">

              <div class="text-1d sm_hide" data-id="7008" v-html="$t('unlimit_dates_you')"></div>
              <div class="text-1d sm_show" data-id="7009" v-html="$t('unlimit_dates_you_m')"></div>

            </div>
          </div>
        </div>
        <div style="height: 25px;"></div>

      </div>

      <!--A few of our success stories-->
      <div class="py-5 bg-faf text-center">
        <h3 class="text-ee1 ff-prata fs-35 mb-3 sm_hide" data-id="7015" v-html="$t('story_h')"></h3>
        <div class="fs-26 text-7e mb-5 sm_hide" data-id="7017" v-html="$t('story_d')"></div>
        <h3 class="fs-29 ff-prata text-ee1 sm_show" data-id="7016" v-html="$t('story_h_m')"></h3>
        <p class="fs-20 mt-3 text-7e sm_show" data-id="7018" v-html="$t('story_d_m')"></p>
        
        
        <div class="mx-auto container" style="max-width: 700px;">
          <div class="sm_hide" style="height: 25px;"></div>
          <div class="row successBox">
            <div class="col-lg-5 col-md-5 col-sm-12">
              <div class="position-relative">
                <img class="successImg" :src="$t('success_story1_img')" />
              </div>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 text-7e">
              <div v-if="curSite.iso2=='GB' || curSite.iso2=='TH'" class="fw-600 mb-3 ff-prata fs-20 text-1d fw-600" data-id="5805" v-html="$t('success_story1_names')"></div>
              
              <div class="fw-600 fs-18 mb-3 successTitle sm_hide" data-id="5803" v-html="$t('success_story1_head')"></div>
              <div class="fw-600 fs-18 mb-3 successTitle sm_show" data-id="6940" v-html="$t('success_story1_head_m')"></div>
              <div class="successDesc sm_hide" data-id="5804" v-html="$t('success_story1_desc')"></div>
              <div class="successDesc sm_show" data-id="6943" v-html="$t('success_story1_desc_m')"></div>
            </div>
          </div>
          <div style="height: 50px;" class="sm_hide"></div>

          <div class="row successBox">
            <div class="col-lg-5 col-md-5 col-sm-12 sm_show">
              <div class="position-relative">
                <img class="successImg" :src="$t('success_story2_img')" />
              </div>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 text-7e">
              <div v-if="curSite.iso2=='GB' || curSite.iso2=='TH'" class="fw-600 mb-3 ff-prata fs-20 text-1d fw-600" data-id="5808" v-html="$t('success_story2_names')"></div>
              
              <div class="fw-600 fs-18 mb-3 successTitle sm_hide" data-id="5806" v-html="$t('success_story2_head')"></div>
              <div class="fw-600 fs-18 mb-3 successTitle sm_show" data-id="6941" v-html="$t('success_story2_head_m')"></div>
              <div class="successDesc sm_hide" data-id="5807" v-html="$t('success_story2_desc')"></div>
              <div class="successDesc sm_show" data-id="6944" v-html="$t('success_story2_desc_m')"></div>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 sm_hide">
              <div class="position-relative">
                <img class="successImg nd" :src="$t('success_story2_img')" />
              </div>
            </div>

          </div>

          <div style="height: 50px;" class="sm_hide"></div>

          <div class="row successBox">
            <div class="col-lg-5 col-md-5 col-sm-12">
              <div class="position-relative">
                <img class="successImg" :src="$t('success_story3_img')" />
              </div>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 text-7e">
              
              <div v-if="curSite.iso2=='GB' || curSite.iso2=='TH'" class="fw-600 mb-3 ff-prata fs-20 text-1d fw-600" data-id="5811" v-html="$t('success_story3_names')"></div>
             
              <div class="fw-600 fs-18 mb-3 successTitle sm_hide" data-id="5809" v-html="$t('success_story3_head')"></div>
              <div class="fw-600 fs-18 mb-3 successTitle sm_show" data-id="6942" v-html="$t('success_story3_head_m')"></div>
              <div class="successDesc sm_hide" data-id="5810" v-html="$t('success_story3_desc')"></div>
              <div class="successDesc sm_show" data-id="6945" v-html="$t('success_story3_desc_m')"></div>
            </div>
          </div>


        </div>

        <div style="height: 50px;"></div>
        <div class="mx-auto px-3" style="max-width: 700px;">
          <div class="matchmakerItem mx-auto mb-5">
         
            <div v-if="curSite.iso2=='GB'" class="bg-image head"
              style="background-image: url(https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_com_black/london/matchmaker_lorraine.jpg);">
            </div>
            
            <div v-else class="bg-image head"
              style="background-image: url(https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls.com/hongkong/matchmaker_angeline.jpg);">
            </div>
            
            <div class="textBox">
              <div style="height: 29px;"></div>
              <div class="text-1d fs-22 mb-4 fw-700 sm_hide" data-id="7010" v-html="$t('forward_out_call')"></div>
              <div class="text-1d fs-20 mb-4 fw-700 sm_show" data-id="7011" v-html="$t('forward_out_call_m')"></div>
              <div class="text-555 mb-3- sm_hide" style="line-height: 33px;" data-id="7012" v-html="$t('will_call_yon')"></div>
              <div class="text-555 mb-3- sm_show" style="line-height: 28px;" data-id="7013" v-html="$t('will_call_yon_m')"></div>

            </div>

          </div>

        </div>

        <div class="text-7e fs-20" data-id="7014" v-html="$t('ty_choose_ls')"></div>
      </div>


      <!-- <div class="py-5 bg-1d">
        <div class="container text-center text-f6f">
          <div style="height: 30px;"></div>
          <div class="mb-4">
            <img class="mb-2"
              src="https://cdn-cf2.lovestruck.com/upload/gen/img/ls-app-6.2/logos/Lovestruck-Ruby-Vertical-Lockup-White-RGB.png"
              width="200" />
          </div>
          <div style="height: 20px;"></div>
          <div class="row mb-4">
            <div class="col-lg-3 col-md-12 col-sm-12 mb-3">
              <p class="text-uppercase" data-id="5161">{'LONDON'|txt}</p>
              <div class="mb-3">3rd Floor, 86-90 Paul Street,<br />London, EC2A 4NE</div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 mb-3">
              <p class="text-uppercase" data-id="2309">{'HongKong'|txt}</p>
              <div class="mb-3" data-id="7082">{'hk_address'|txt}</div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 mb-3">
              <p class="text-uppercase" data-id="2905">{'Singapore'|txt}</p>
              <div class="mb-3">20 Cecil Street, 14th floor,<br />PLUS building, Singapore 049705</div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 mb-3">
              <p class="text-uppercase" data-id="2165">{'Bangkok'|txt}</p>
              <div class="mb-3">388 Exchange Tower, 29th Fl.,<br />Unit 2901 - 2904, Sukhumvit Rd.,<br />Klongtoey District,
                Bangkok 10110</div>
            </div>
          
          </div>

          <div class="mb-3">
            <a href="/{$lang_path}/{$path_lang_key}/contact-us/" class="text-f6f"
              data-id="3188">{'web_lbl_account_contact'|txt}</a><span class="mx-3">|</span>
            <a href="/{$lang_path}/{$path_lang_key}/terms/" class="text-f6f" data-id="5568">{'terms_txt'|txt}</a><span
              class="mx-3 sm_hide_line">|</span><br class="sm_show" />
            <a href="/{$lang_path}/{$path_lang_key}/privacy/" class="text-f6f" data-id="3649">{'privacy_policy_text'|txt}</a>
          </div>

          <div>© {$smarty.now|date_format:"%Y"} {$company_name}</div>
        </div>
      </div> -->


      <div class="sm_show" style="height: 50px;"></div>
    </div>
  </div>
</template>
<script>

import topName from "../common/topNav";
export default {
  name: "matchmaking-callback-v2",
  components: {
    topName,
  },
  data() {
    return {
      type: "cb",
      // userInfo: this.$storage.get("userInfo"),
      cdnBase: "https://cdn-cf2.lovestruck.com/upload-m/gen/img/",
      currentData: null,
      matchmakers: null,
      successStories: null,
      cdnDir: "hongkong",
      curSite: this.$store.getters.curSite,
      hiText: "",
      callYouOn: "",
      callImg: null,
      lang: this.$store.getters.curLang,
      thxIframeUrl: null
    };
  },
  mounted() {
    let self = this;
    self.$api
      .getClientMe(self.$store.state)
      .then((rs) => {
        self.currentData = rs;
        // let city_url = _.get(self.currentData, "client.city.city_url");
        // if (city_url == "london") {
        //   self.callImg =
        //     "https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_com_black/calling_desktop_uk.png";
        // } else {
        //   self.callImg =
        //     "https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.0/call_back.jpg";
        // }
        // thx_url = thx_url.replace('[CITY_PATH]', city_url);
        // thx_url = thx_url.replace('[LSI_LANG]', self.getLsiLang());

        // thx_url = thx_url.replace('[FIRST_NAME]', encodeURIComponent(self.currentData.client.first_name));
        // thx_url = thx_url.replace('[MOBILE]', encodeURIComponent(`${self.currentData.client.phone_number}`));
        
        // // 
        // if(_.get(self.currentData, "booking")){
        //   //
        //   thx_url = thx_url.replace('[BOOK_TIME]', encodeURIComponent(_.get(self.currentData, "booking.consultation_time")));
        // }else{
        //   thx_url = thx_url.replace('[BOOK_TIME]', '');
        // }
        setTimeout(function(){
          self.setPhone()
        },300);
        //self.thxIframeUrl = thx_url;
        // console.log(thx_url)
        if (
          _.get(self.currentData, "client.membership_level_id") <= 1 &&
          _.get(self.currentData, "client.total_matchmaker_msg") < 10
        ) {
          self.$api.getSendFirstMatchmakerMsg("", self.$store.state);
        }
        // console.log('currentData', self.currentData)
        if (_.get(self.currentData, "client.membership_has_expired") == 1) {
          self.$router.push({ name: "renew-payment" });
          return false;
        } else {
          self.hiText = self
            .$t("hi_someone")
            .replace("[username]", self.currentData.client.first_name);
          self.callYouOn = self
            .$t("will_call_you_on")
            .replace(
              "[phone_mobile]",
              `<b class="text-ee1">${self.currentData.client.phone_number}</b>`
            );
          // return self.$api.getPageData("", self.$store.state);
        }
      })
      .then((rs) => {
        // console.log('getSuccessStories', rs)
        // self.successStories = _.get(rs, "successStories");
        // self.matchmakers = _.get(rs, "matchmakers");
        // self.top_image_url = _.get(rs, 'top_image_url')
      });
  },
  methods:{
    setPhone(){
      let self = this;
      $('.leadName').html(self.currentData.client.firstname);
      $('.phoneNumber').html(`${self.currentData.client.country_phone_code}${self.currentData.client.phone_number}`);
      let book_time = _.get(self.currentData, "booking.consultation_time");
      // book_time = '2025-01-30 12:00:00';
      if(book_time){
        let bookTime = self.$moment(book_time);
        // console.log(`at ${bookTime.format('hha')} on ${bookTime.format('DD MMMM')}`)
        $('.bookTime').html(`at ${bookTime.format('hha')} on ${bookTime.format('DD MMMM')}`)
      }
      $('.welcomeText').show();
    },
    getLsiLang(){
      let self = this;
      let langMaps = {
        'en_us': 'en',
        'zh_cn': 'zh-CN',
        // 'zh-cn' => 2,
        'zh_hk': 'zh-HK',
        // 'zh-hk' => 3,
        // 'zh-tw' => 3,
        'th': 'th',
        // 'jp' => 5,
      }

      return _.get(langMaps, self.lang, 'en');
    }
  }
};
</script>
<style>
.bg-image {
    background-image: none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.text-555 {
    color: #555;
}
.text-f6f {
    color: #F6F1EB;
}
.matchmakerItem {
    max-width: 760px;
    width: 100%;
    border-bottom: 5px solid #EE143D;
    box-shadow: 0 3px 40px #00000012;
    background-color: #fff;
    padding: 1.5rem;

    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
}

.matchmakerItem .head {
    width: 150px;
    height: 150px;
    min-width: 150px;
    border-radius: 75px;
    background-position: top;
}

.matchmakerItem .textBox {
    text-align: left;
    margin-left: 1.5rem;
}
.rightPathText {
    font-size: 21px;
}
.grade-line {
    max-width: 200px;
    width: 100%;
    height: 2px;
    background-image: linear-gradient(90deg,
            rgba(255, 255, 255, 0.1) 0%,
            rgba(238, 20, 61, 1) 50%,
            rgba(255, 255, 255, 0.1) 100%);
}

.happenItemN {
    position: relative;
    z-index: 2;
    width: 43px;
    height: 43px;
    border-radius: 22px;
    background: #F6F1EB;
    border: 1px solid #D9CEC2;
    color: #555;
    line-height: 43px;
    text-align: center;
}

.happenItemN.v12 {
    background: #fff;
    border: 1px solid #F96038;
}

.happenLine {
    position: absolute;
    width: 1px;
    height: 75%;
    background: #D9CEC2;
    left: 22px;
    top: 6px;
    z-index: 0;
}
.successBox {
    background: #fff;
    padding: 1.5rem 0;
    margin-bottom: 1.5rem;
}

.successTitle,
.successDesc {

    background-size: 50px;
    background-repeat: no-repeat;
}

.successDesc {
    min-height: 130px;
}

.successImg {
    position: absolute;
    left: -53px;
    top: -61px;
    width: 280px;
}

.successImg.nd {
    position: absolute;
    left: unset;
    right: -53px;
    top: -61px;
    width: 280px;
}

.matchmaker-quto-left,
.matchmaker-quto-right {
    background-size: 30px;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    position: absolute;
}

.successTitle,
.matchmaker-quto-left {
    background-image: url(https://cdn-cf2.lovestruck.com/upload-m/gen/img/v15/quto_left.png);
    background-position: left;
}

.successDesc,
.matchmaker-quto-right {
    background-image: url(https://cdn-cf2.lovestruck.com/upload-m/gen/img/v15/quto_right.png);
    background-position: right bottom;
}
.matchmaker-quto-left {
    left: 0;
    top: -19px;
}

.matchmaker-quto-right {
    right: 0;
    bottom: 30px;
}
@media (max-width: 768px){
  .successBox {
        background: #FAF8F2;
        padding-bottom: 0;
        margin-bottom: unset;
    }

    .successTitle {
        margin-top: 15px;
    }

    .successDesc {
        padding-bottom: 20px;
        min-height: auto;
    }

    .rightPathText {
        font-size: 18px;
    }

    .successImg {
        max-width: 400px;
        width: 100%;
        position: unset;
    }

    .matchmakerItem {
        display: block !important;
        text-align: center;
    }

    .matchmakerItem .head {
        margin: 0 auto;
    }

    .matchmakerItem .textBox {
        text-align: center;
        margin-top: 15px;
        margin-left: 0;
    }

}
</style>